import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import APIs from '../docs/databases/_apis.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeSet = makeShortcode("CodeSet");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Geolocating an IP address using GeoIP2 and GeoLite2 databases consists of
configuring a database reader and querying the database.`}</p>
    <div {...{
      "id": "toc-implementation"
    }}><h2 {...{
        "id": "implementation",
        "parentName": "div"
      }}>{`Implementation`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`MaxMind offers and highly recommends using
`}
        <a {...{
          "href": "/geoip/docs/databases#official-client-apis",
          "parentName": "p"
        }}>{`official client libraries`}</a>
        {` to query
our databases.`}</p>
      <div {...{
        "id": "toc-1-install-the-geoip2-client-library",
        "parentName": "div"
      }}><h3 {...{
          "id": "1-install-the-geoip2-client-library",
          "parentName": "div"
        }}>{`1. Install the GeoIP2 client library`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We have a collection of officially supported libraries for you to query with the
GeoIP2 and GeoLite2 databases:`}</p>
        <CodeSet mdxType="CodeSet">
          <pre><code {...{
              "className": "language-cli-csharp",
              "parentName": "pre"
            }}>{`# Install via NuGet
Install-Package MaxMind.GeoIP2
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-java",
              "parentName": "pre"
            }}>{`# Install via Maven, recommended
<dependency>
  <groupId>com.maxmind.geoip2</groupId>
  <artifactId>geoip2</artifactId>
  <version>2.15.0</version>
</dependency>

# Or install via Gradle
repositories {
  mavenCentral()
}
dependencies {
  compile 'com.maxmind.geoip2:geoip2:2.15.0'
}
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-javascript",
              "parentName": "pre"
            }}>{`# Install via npm
npm install @maxmind/geoip2-node

# Or install via yarn
yarn add @maxmind/geoip2-node
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-php",
              "parentName": "pre"
            }}>{`# Install via Composer
composer require geoip2/geoip2:~2.0
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-python",
              "parentName": "pre"
            }}>{`# Install via pip
pip install geoip2
`}</code></pre>
          <pre><code {...{
              "className": "language-cli-ruby",
              "parentName": "pre"
            }}>{`# Install as a gem
gem install maxmind-geoip2

# Or add this to your Gemfile
gem 'maxmind-geoip2'
`}</code></pre>
        </CodeSet></div>
      <div {...{
        "id": "toc-2-configure-a-geoip2-database-reader-to-query-the-database",
        "parentName": "div"
      }}><h3 {...{
          "id": "2-configure-a-geoip2-database-reader-to-query-the-database",
          "parentName": "div"
        }}>{`2. Configure a GeoIP2 database reader to query the database`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Configuring the database reader requires the database file to be accessible on
the filesystem. After configuring the database reader, you can then query the
database by calling the method corresponding to the database type (e.g. `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`city`}</inlineCode>
          {`
or `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`country`}</inlineCode>
          {`) and passing it the IP address you want to look up.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`If the lookup succeeds, the method call will return a model class/object for the
database method you called. This model in turn contains multiple record
classes/objects, each of which represents part of the data for the record.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`If the request fails, the reader class will throw an exception or return an
error depending on the library.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`For more details on database methods, errors, and exceptions,
`}
          <a {...{
            "href": "#apis-and-third-party-integrations",
            "parentName": "p"
          }}>{`see the client API documentation below`}</a>
          {`.`}</p>
        <CodeSet mdxType="CodeSet">
          <pre><code {...{
              "className": "language-csharp",
              "parentName": "pre"
            }}>{`using (var reader = new DatabaseReader("path/to/maxmind-database.mmdb"))
{
    var response = reader.City("128.101.101.101");

    Console.WriteLine(response.Country.IsoCode);
}
`}</code></pre>
          <pre><code {...{
              "className": "language-java",
              "parentName": "pre"
            }}>{`File database = new File("/path/to/maxmind-database.mmdb")

// This reader object should be reused across lookups as creation of it is
// expensive.
DatabaseReader reader = new DatabaseReader.Builder(database).build();

// If you want to use caching at the cost of a small (~2MB) memory overhead:
// new DatabaseReader.Builder(file).withCache(new CHMCache()).build();

InetAddress ipAddress = InetAddress.getByName("128.101.101.101");

CityResponse response = reader.city(ipAddress);

Country country = response.getCountry();
System.out.println(country.getIsoCode());

`}</code></pre>
          <pre><code {...{
              "className": "language-javascript",
              "parentName": "pre"
            }}>{`// Asynchronous database opening
const Reader = require('@maxmind/geoip2-node').Reader;

Reader.open('/path/to/maxmind-database.mmdb').then(reader => {
  const response = reader.city('128.101.101.101');

  console.log(response.country.isoCode);
});


// Synchronous database opening
const fs = require('fs');
const Reader = require('@maxmind/geoip2-node').Reader;

const dbBuffer = fs.readFileSync('/path/to/maxmind-database.mmdb');

// This reader object should be reused across lookups as creation of it is
// expensive.
const reader = Reader.openBuffer(dbBuffer);

response = reader.city('128.101.101.101');

console.log(response.country.isoCode);
`}</code></pre>
          <pre><code {...{
              "className": "language-php",
              "parentName": "pre"
            }}>{`<?php
require_once 'vendor/autoload.php';
use GeoIp2\\Database\\Reader;

// This reader object should be reused across lookups as creation of it is
// expensive.
$reader = new Reader('/path/to/maxmind-database.mmdb');

$record = $reader->city('128.101.101.101');

print($record->country->isoCode);
`}</code></pre>
          <pre><code {...{
              "className": "language-python",
              "parentName": "pre"
            }}>{`import geoip2.database

# This reader object should be reused across lookups as creation of it is
# expensive.
with geoip2.database.Reader('/path/to/maxmind-database.mmdb') as reader:
    response = reader.city('128.101.101.101');
    print(response.country.iso_code)
`}</code></pre>
          <pre><code {...{
              "className": "language-ruby",
              "parentName": "pre"
            }}>{`require 'maxmind/geoip2'

# This reader object should be reused across lookups as creation of it is
# expensive.
reader = MaxMind::GeoIP2::Reader.new('/path/to/maxmind-database.mmdb')

record = reader.city('128.101.101.101')

puts record.country.iso_code
`}</code></pre>
        </CodeSet></div></div>
    <div {...{
      "id": "toc-apis-and-third-party-integrations"
    }}><h2 {...{
        "id": "apis-and-third-party-integrations",
        "parentName": "div"
      }}>{`APIs and Third-Party Integrations`}</h2>
      <APIs mdxType="APIs" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      